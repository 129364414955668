import axios from "axios";
import { ElMessageBox } from "element-plus";
import { BaseApiURL, ContentType } from "./constant";
import { Message } from "./message";

// 区分线上环境 ： 开发环境
axios.defaults.baseURL =
  process.env.NODE_ENV === "production" ? BaseApiURL : BaseApiURL; // '线上环境的接口前缀地址' : '测试环境的接口前缀地址';
// 跨域请求时发送Cookie
axios.defaults.withCredentials = true;
// 请求头，headers 信息
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers['token'] = localGet('token') || ''
// 默认 post 请求，使用 application/json 形式
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";
//请求超时时间 （10s）
// axios.defaults.timeout = 10000;

// 声明一个axios拦截器 请求前拦截 一般用于携带token
axios.interceptors.request.use(
  (config) => {
    // 设置请求头
    // config.headers['authorizationToken'] = store.state.token || localStorage.getItem("token");
    return config;
  },
  (err) => {
    // Do something with request error
    return Promise.reject(err);
  }
);

// 声明一个axios拦截器 请求后拦截 服务器返回结果时候判断再返回
axios.interceptors.response.use(
  (res) => {
    // console.log(res);
    return res;
    // if (res.status === 200) {
    //   return res.data;
    // } else {
    //   console.log(res);
    //   return res;
    // }
  },
  (err) => {
    ElMessageBox.alert(Message.systemError, Message.error, {
      type: "error",
      confirmButtonText: Message.confirm,
      callback: () => {
        console.error(err);
      },
    });
    return err;
  }
);

export const request = {
  post(url: string, data = {}) {
    // 判断用户传递的URL是否/开头,如果不是,加上/
    url = url.indexOf("/") == 0 ? url : "/" + url;

    return axios.post(url, data, {
      headers: {
        "Content-Type": ContentType.json,
      },
    });
  },
  get(url: string) {
    url = url.indexOf("/") == 0 ? url : "/" + url;
    // 直接将data序列化
    // return axios.get(url + '?' + qs.stringify(data))
    return axios.get(url, {
      headers: {
        "Content-Type": ContentType.json,
      },
    });
    // return axios.get(url + "?" + data);
  },
  downloadFileG(url: string) {
    url = url.indexOf("/") == 0 ? url : "/" + url;
    // 直接将data序列化
    // return axios.get(url + '?' + qs.stringify(data))
    return axios.get(url, {
      headers: {
        "Content-Type": ContentType.json,
      },
      responseType: "blob", // 表明返回服务器返回的数据类型
    });
  },
};
// export const { post, get } = request;
