export default {
  name: "app",

  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))));
    } // 在页面刷新时将store保存到sessionStorage里


    window.addEventListener("beforeunload", () => {
      console.log("刷新冲在");
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  }

};