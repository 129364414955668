const getters = {
  getCurrentMenu: (state: any) => state.currentMenu,
  getIsSidebarNavCollapse: (state: any) => state.isSidebarNavCollapse,
  getIsTimeout: (state: any) => state.isTimeout,
  getLoginUserInfo: (state: any) => state.loginUserInfo,
  getKeywordsInput: (state: any) => state.keywordsInput,
  // roleCheck2: (state: any, auths: Array<any>): boolean => {
  //   if (!auths) {
  //     return true;
  //   }
  //   if (!state.loginUserInfo || !state.loginUserInfo.id) {
  //     return false;
  //   }
  //   if (auths.indexOf(state.loginUserInfo.type) >= 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // },
  roleCheck: (state: any) => {
    return function (auths: Array<any>) {
      if (!auths) {
        return true;
      }
      if (!state.loginUserInfo || !state.loginUserInfo.id) {
        return false;
      }
      if (auths.indexOf(state.loginUserInfo.type) >= 0) {
        return true;
      } else {
        return false;
      }
    };
  },
};
export default getters;
