import store from "@/store";
import { createRouter, createWebHashHistory } from "vue-router";
// 组件
import AppLayoutWeb from "../components/layout-web/LayoutWeb.vue";

const routes: Array<any> = [
  {
    path: "",
    name: "banner",
    component: () => import("../views/banner/banner.vue"), // 路由懒加载
    // component: () => import("../views/top/top.vue"), // 路由懒加载
  },
  // top
  {
    path: "/",
    component: AppLayoutWeb,
    redirect: "",
    children: [
      {
        path: "",
        component: () => import("../components/layout-web/LayoutWeb.vue"), // 路由懒加载
        meta: {
          name: "首页",
          icon: "",
        },
        beforeEnter: (to: any, from: any, next: () => void) => {
          console.log("前置路由守卫");
          next();
        },
      },
    ],
  },
  {
    path: "/first",
    component: AppLayoutWeb,
    redirect: "",
    children: [
      {
        path: ":id",
        component: () => import("../views/first/first.vue"), // 路由懒加载
        meta: {
          name: "首页",
          icon: "",
        },
        beforeEnter: (to: any, from: any, next: () => void) => {
          console.log("前置路由守卫");
          next();
        },
      },
    ],
  },
  {
    path: "/second",
    component: AppLayoutWeb,
    redirect: "",
    children: [
      {
        path: ":id",
        component: () => import("../views/second/second.vue"), // 路由懒加载
        meta: {
          name: "内容",
          icon: "",
        },
        beforeEnter: (to: any, from: any, next: () => void) => {
          console.log("二级前置路由守卫");
          next();
        },
      },
    ],
  },
  {
    path: "/search",
    component: AppLayoutWeb,
    redirect: "",
    children: [
      {
        path: "keyword/contents",
        component: () => import("../views/keywords/keywords.vue"), // 路由懒加载
        meta: {
          name: "关键词搜索",
          icon: "",
        },
      },
    ],
  },
  {
    path: "/audio",
    component: AppLayoutWeb,
    redirect: "",
    children: [
      {
        path: "list",
        component: () => import("../views/audio/audio.vue"), // 路由懒加载
        meta: {
          name: "音频列表",
          icon: "",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("beforeEach");
  if (to.path === "/") {
    next({ path: "/first" });
  } else {
      next();
  }
});
router.afterEach((to, from, next) => {
  console.log("afterEach");
  try {
    //设置标题
    if (to.meta && to.meta.name) {
      document.title = typeof to.meta.name === "string" ? to.meta.name : "";
    }
  } catch (err) {
    console.log("fail");
  }
});

export default router;