export const Message = {
  tableNoData: "暂无数据",
  success: "成功",
  error: "失败",
  warning: "警告",
  confirm: "确认",
  cancel: "取消",
  isEmptyMsg: "请输入！",
  systemError: "系统异常，请联系管理员！",
  logOutMsg: "确定退出登录?",
  logOutSuccessMsg: "退出登录成功！",
  signOut: "退出",
  audioExist: "当前音频不存在！",
  audioPlayEnd: "播放结束！",
};
