import { createStore } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
export default createStore({
  // state是用来定义数据
  state: {
    // permissionList: null /** 所有路由 */,
    // sidebarMenu: [] /** 导航菜单 */,
    currentMenu: "", // 当前active导航菜单
    isSidebarNavCollapse: false, // 导航菜单是否折叠 false:展开 true:收缩
    operationTime: 0, // 操作时间
    isTimeout: false, //  是否超时
    loginUserInfo: {}, // 登录用户信息
    currentList:[],//导航栏列表
    userType:4, //用户权限 示例---0超级管理员 1内容管理员 2部门管理员 3成员 4游客
    defaultIndexId:'',//默认首页点击id
    secondTag:0,//是都为第二层
    infoList:[],//一级页面数据
    keywordsInput: "", // 关键词
  },
  // mutations通常为修改state数据而使用，这用就可以避免直接修改state的数据
  mutations,
  // actions当你的数据是需要发送请求获取时
  actions,
  // getters用来处理数据，对state中的数据进行处理，得到自己想要的效果
  getters,
});
