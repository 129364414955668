// export const BaseApiURL = "http://192.168.10.70:8002"; // 上线需要修改地址
export const BaseApiURL = "http://www.nuolanyi.com:2020"; // 上线需要修改地址
// export const BaseApiURL = "http://192.168.10.70:8001/api_manage"; // 上线需要修改地址
// export const BaseImgURL = "http://192.168.10.54:2020"; // 上线需要修改地址

export const ContentType = {
  json: "application/json",
  formData: "multipart/form-data",
};

export const Role = {
  invalid: -1, // 无效人员
  admin: 0, // 超管
  contentManager: 1, // 内容管理员
  departManager: 2, // 部门管理员
  member: 3, // 成员
  tourists: 4, // 游客
};

export const RoleLabel = {
  invalid: "无效人员", // 无效人员
  admin: "超级管理员", // 超管
  contentManager: "内容管理员", // 财务
  departManager: "部门管理员", // 财务
  member: "成员", // 仓管
  tourists: "游客", // 商务
};

export const RoleList = [
  { label: "超级管理员", value: Role.admin },
  { label: "内容管理员", value: Role.contentManager },
  { label: "部门管理员", value: Role.departManager },
  { label: "成员", value: Role.member },
  { label: "游客", value: Role.tourists },
];

export const ProjectStatus = {
  progressing: 0, // 0进行中
  shelve: 1, // 1搁置
  completed: 2, // 2已完成
};

export const ProjectStatusList = [
  { label: "进行中", value: ProjectStatus.progressing },
  { label: "搁置", value: ProjectStatus.shelve },
  { label: "已完成", value: ProjectStatus.completed },
];

export const ApiUrl = {
  login: "/api_app/login", // 登录
  navigation:"/api_app/menus/list",// 顶部导航栏列表
  navigationAll: "/api_app/menus/list/all",
  webInfo:'/api_manage/info/list',//底部公司信息
  firstList:'/api_app/items/list',//栏目列表
  firstInfo:'/api_app/content/list',// 栏目内容列表
  coverImg:'/api_manage/cover/pic',//内容封面图片
  infoImg:'/api_manage/info/pic',//网站信息图片
  userInfoImg:'/api_manage/users/pic',//用户头像
  infoDetail:'/api_manage/content/info',//内容详情
  uploadFile:'/api_manage/files/update',//文件上传
  contentsSearch: '/api_app/contents/search', // 关键词搜索
  fileDataData: '/api_manage/fileData/data', // 获取最新音频 信息
  fileDataList: '/api_manage/fileData/list', // 获取音频list
  fileDataPic: '//api_app/fileData/pic', // 获取最新音频 图片
  fileDataAudio: '/api_manage/fileData/audio', // 获取mp3
};

export const Status = {
  add: "add",
  edit: "edit",
  delete: "delete",
};

export const TablePageSizes = [5, 10, 20, 30, 50];
