import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import { ref, inject } from 'vue';
import VueSplide from '@splidejs/vue-splide';


const app = createApp(App);
// 注册elementplus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// app.use(i18n);
app.use( VueSplide );
app.use(store);
app.use(router);
app.mount("#app");
