const mutations = {
  // 当前选中的导航
  setCurrentMenu(state: any, currentMenu: string) {
    state.currentMenu = currentMenu;
  },
  // 导航栏是否折叠
  setIsSidebarNavCollapse(state: any) {
    state.isSidebarNavCollapse = !state.isSidebarNavCollapse;
  },
  // 操作时间
  setoperationTime(state: any) {
    state.operationTime = new Date().getTime();
  },
  // 是否超时，超过1小时
  setIsTimeout(state: any) {
    const nowTime = new Date().getTime();
    const hm = nowTime - state.operationTime;
    if (state.operationTime && hm > 3600000) {
      state.isTimeout = true;
    } else {
      state.operationTime = nowTime;
      state.isTimeout = false;
    }
  },
  // 更新登录用户信息
  setLoginUserInfo(state: any, info: any) {
    state.loginUserInfo = info;
  },
  // 更新关键词
  setKeywordsInput(state: any, value: string) {
    state.keywordsInput = value;
  },
};
export default mutations;
